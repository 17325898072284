import styled from "@emotion/styled";
import { Grid } from "@mui/material";
const PageWrapper = styled.div``;

const Image = styled.img`
  width: 100%;
  height: auto;
  margin: ${(props) => (props.m ? props.m : "0px")};
  max-width: ${(props) => (props.mw ? props.mw : "40px")};
  @media (max-width: 599px) {
    max-width: ${(props) => props.mw599};
  }
`;

const AbsoluteImage = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  top: ${(props) => (props.t ? props.t : "0")};
  left: ${(props) => (props.l ? props.l : "0")};
  margin: ${(props) => (props.m ? props.m : "0px")};
  max-width: ${(props) => (props.w ? props.w : "80px")};
  z-index: 0;
  @media (max-width: 599px) {
    max-width: ${(props) => props.w599};
    top: ${(props) => props.t599};
    left: ${(props) => props.l599};
  }
`;
const FlexDiv = styled.div`
  width: ${(props) => (props.w ? props.w : "100%")};
  height: auto;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  margin: ${(props) => (props.m ? props.m : "0px")};
  padding: ${(props) => (props.p ? props.p : "10px")};
  max-width: ${(props) => (props.w ? props.w : "100%")};
  column-gap: 10px;
  @media (max-width: 599px) {
    max-width: ${(props) => props.w};
  }
`;
const OuterColorGrid = styled(Grid)`
  width: 100%;
  min-height: ${(props) => (props.mh ? props.mh : "300px")};
  position: relative;
  display: flex;
  flex-direction: ${(props) => (props.fd ? props.fd : "column")};
  justify-content: ${(props) => (props.jc ? props.jc : "start")};
  align-items: ${(props) => (props.ai ? props.ai : "start")};
  transition: 0.5s ease;
  gap: 10px;
  padding: ${(props) => (props.p ? props.p : "20px")};
  background: ${(props) => (props.bgc ? props.bgc : "#111111")};
  /* @media(max-width:899px){
        margin: 20px 0;
    } */
`;
const OuterGradiantDiv = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease;
  background: ${(props) =>
    props.bgc ? props.bgc : "linear-gradient(to top, #fff, transparent)"};
  /* @media(max-width:899px){
        margin: 20px 0;
    } */
`;
const SecondGradiantDiv = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 1px 1px;
  background: #050c24;
`;
const GradiantDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.ai ? props.ai : "center")};
  background: ${(props) =>
    props.bgc
      ? props.bgc
      : "linear-gradient(to top, #ffffff20, transparent 130%)"};
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  margin: ${(props) => (props.m ? props.m : "0px")};
  padding: ${(props) => (props.p ? props.p : "50px")};
  max-width: ${(props) => (props.w ? props.w : "100%")};
  @media (max-width: 599px) {
    padding: ${(props) => (props.p599 ? props.p599 : "10px")};
  }
`;

export {
  PageWrapper,
  Image,
  FlexDiv,
  GradiantDiv,
  OuterGradiantDiv,
  OuterColorGrid,
  SecondGradiantDiv,
  AbsoluteImage,
};
