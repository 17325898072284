import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React from "react";
import SectionsHeading from "../../SectionsHeading";
import { FlexDiv, Image} from "../../../Styles/style";
import tokenImage from "../../../../assets/tokenImage.png";

const GridWraper = styled(Grid)`
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction:column; */
  justify-content: center;
  align-items: start;
  padding: 50px 0;
`;
const ContinerWraper = styled(Container)`
  height: auto;
  width: 100%;
  padding: 0 !important;
`;

const PackagesSection = () => {
  return (
    <ContinerWraper maxWidth="xxl" id="features">
      <Container>
        <GridWraper container>
          <Grid
            item
            xs={12}
            sm={10}
            sx={
              {
                // padding: "20px 0 30px",
              }
            }
          >
            <SectionsHeading
              data-aos="fade-up"
              color="red"
              fs="100px"
              fs899="50px"
              fs599="46px"
            >
              Tokenomics
            </SectionsHeading>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "grid",
              placeItems: "center",
              padding: "0px 0 0",
            }}
          >
            <Image data-aos="zoom-in" mw="25%" mw599="50%" src={tokenImage} />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              padding: "20px 0 30px",
            }}
          >
            <SectionsHeading
              data-aos="fade-up"
              color="red"
              fs="60px"
              fs899="50px"
              fs599="46px"
            >
              Nighty five percent into liquidty
            </SectionsHeading>
            <FlexDiv data-aos="fade-up">
              <SectionsHeading
                color="#FFD500"
                fs="60px"
                fs899="50px"
                fs599="46px"
              >
                Five percent into not liquidity
              </SectionsHeading>
            </FlexDiv>
          </Grid>
        </GridWraper>
      </Container>
    </ContinerWraper>
  );
};

export default PackagesSection;
