import './App.css';

import { useEffect } from 'react';
import AOS from 'aos';
import MainPage from './Views/Pages/Main';


function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true,     // Only animate elements once
    });
  }, []);
  return (
      <MainPage />
  );
}

export default App;
