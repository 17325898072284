import styled from "@emotion/styled";
import { Container, Grid, Link } from "@mui/material";
import React from "react";
import SectionsHeading from "../../SectionsHeading";
import { Image } from "../../../Styles/style";
import aboutImage from "../../../../assets/aboutImage.png";
import etherscan from "../../../../assets/etherscan.png";
import Paragraph from "../../Paragraph";

const GridWraper = styled(Grid)`
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction:column; */
  justify-content: space-between;
  align-items: start;
  padding: 50px 0;
  /* gap: 20px; */
  @media (max-width: 599px) {
    padding: 100px 0 0;
  }
`;
const ContinerWraper = styled(Container)`
  height: auto;
  width: 100%;
  padding: 0 !important;
`;

const AboutSection = () => {
  return (
    <ContinerWraper maxWidth="xxl" id="about">
      <Container>
        <GridWraper container>
          <Grid item xs={12} sm={4}>
            <Image mw="100%" src={aboutImage} alt="" />
          </Grid>
          <Grid item xs={12} sm={8}>
            <SectionsHeading
              fs="80px"
              fs899="50px"
              fs599="46px"
              color="#FFD500"
              ta="right"
              data-aos="zoom-in"
            >
              What is ADHD?
            </SectionsHeading>
            <Paragraph
              color="#06A125"
              fs="60px"
              fs599="40px"
              ta="left"
              data-aos="fade-up"
            >
              Adhd is an innovative crypto.. ummm innovative crypto... It's a
              good community!
            </Paragraph>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "end",
              flexDirection: { xs: "column", sm: "row" },
              // p: { xs: "20px", lg: "20px 0" },
            }}
          >
            <Paragraph
              ta="left"
              fs="80px"
              fs899="50px"
              fs599="46px"
              data-aos="fade-up"
            >
              Here is the <br /> donation address:
            </Paragraph>
            <Link
              href="https://etherscan.io/address/0x812659e3b159f860508d9bc5ce97c0b7a99b10c6"
              target="blank"
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "start",
                columnGap: "10px",
                textDecoration: "none",
                p: "0px 0 40px",
              }}
            >
              <Image mw="70px" src={etherscan} alt="" />
              <SectionsHeading>Etherscan</SectionsHeading>
            </Link>
          </Grid>
        </GridWraper>
      </Container>
    </ContinerWraper>
  );
};

export default AboutSection;
