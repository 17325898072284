import React, { useEffect } from "react";
import HeroSection from "../../Components/Sections/S1-Hero";
import AboutSection from "../../Components/Sections/S2-About";
import PackagesSection from "../../Components/Sections/S3-Packages";
import RoadmapSection from "../../Components/Sections/S4-Roadmap";
import AOS from "aos";
import "aos/dist/aos.css";

const MainPage = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once:false,
      mirror: true,
    });
  });
  return (
    <div
    style={{
      background: "#ffffff",
      overflow:"hidden"
    }}
  >
      <HeroSection />
      <AboutSection />
      <PackagesSection />
      <RoadmapSection />
    </div>
  );
};

export default MainPage;
