import styled from "@emotion/styled";
import { Container, Grid} from "@mui/material";
import React from "react";
import { Image } from "../../../Styles/style";
import meme1 from "../../../../assets/meme1.png";
import meme2 from "../../../../assets/meme2.png";
import meme3 from "../../../../assets/meme3.png";
import meme4 from "../../../../assets/meme4.png";
import meme5 from "../../../../assets/meme5.png";
import meme6 from "../../../../assets/meme6.png";
import meme7 from "../../../../assets/meme7.png";
import meme8 from "../../../../assets/meme8.png";
import meme9 from "../../../../assets/meme9.png";
import meme10 from "../../../../assets/meme10.png";
import meme11 from "../../../../assets/meme11.png";
import meme12 from "../../../../assets/meme12.png";

const GridWraper = styled(Grid)`
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction:column; */
  justify-content: space-evenly;
  align-items: start;
  padding: 100px 0 10px;
  gap: 35px;
  @media (max-width: 899px) {
  }
`;

const ContinerWraper = styled(Container)`
  height: auto;
  width: 100%;
  padding: 0 !important;
`;
const DisclamerSection = () => {
  return (
    <ContinerWraper maxWidth="xxl">
      <Container>
        <GridWraper container>
          <Grid sx={{gap:"35px"}}  container item xs={12} sm={5} md={3}>
            <Grid item xs={12}>
              <Image style={{transform:"rotate(12deg)",animation: "1s infinite alternate rotation"}} mw="100%" src={meme1} alt="" />
            </Grid>
            <Grid item xs={12}>
              <Image style={{padding:"20px 0px 0 40px",animation: "1s infinite alternate rotationREVERS"}} mw="100%" src={meme4} alt="" />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={5} md={4}>
            <Image style={{margin:"20px 0px 0 40px",animation: "2s infinite alternate slidein"}} mw="100%" src={meme2} alt="" />
          </Grid>
          <Grid sx={{gap:"35px"}} container item xs={12} sm={5} md={4}>
            <Grid item xs={12}>
              <Image  style={{margin:"100px 0px 0 100px",animation: "1s infinite alternate wiggleREVERSE"}} mw="100%" src={meme3} alt="" />
            </Grid>
            <Grid item xs={12}>
              <Image style={{margin:"0px 100px 0 0px",animation: "2s infinite alternate wiggle"}} mw="100%" src={meme5} alt="" />
            </Grid>
          </Grid>
          <Grid sx={{gap:"35px"}} container item xs={12} sm={5} md={3}>
            <Grid item xs={12}>
              <Image style={{transform:"rotate(-8deg)",margin:"0px 100px 0 0px",animation: "2s infinite alternate slidein"}} mw="100%" src={meme6} alt="" />
            </Grid>
            <Grid item xs={12}>
              <Image style={{animation: "1s infinite alternate rotation"}} mw="100%" src={meme9} alt="" />
            </Grid>
          </Grid>
          <Grid sx={{gap:"35px",animation: "2s infinite alternate wiggle"}} container item xs={12} sm={5} md={3.7}>
            <Grid item xs={12}>
              <Image style={{animation: "1s infinite alternate wiggleREVERSE"}} mw="100%" src={meme7} alt="" />
            </Grid>
            <Grid item xs={12}>
              <Image style={{transform:"rotate(-12deg)",margin:"100px 0px 0 50px",animation: "1s infinite alternate rotationREVERS"}} mw="100%" src={meme10} alt="" />
            </Grid>
          </Grid>
          <Grid sx={{gap:"35px",animation: "2s infinite alternate slidein"}} container item xs={12} sm={5} md={3}>
            <Grid item xs={12}>
              <Image style={{transform:"rotate(10deg)"}} mw="100%" src={meme8} alt="" />
            </Grid>
            <Grid item xs={12}>
              <Image style={{padding:"0px 100px 0 0px",animation: "2s infinite alternate wiggle"}} mw="100%" src={meme12} alt="" />
            </Grid>
            <Grid item xs={12}>
              <Image mw="100%" style={{animation: "1s infinite alternate rotation"}}src={meme11} alt="" />
            </Grid>
          </Grid>
        </GridWraper>
      </Container>
    </ContinerWraper>
  );
};

export default DisclamerSection;
