import React from "react";
import { Container, Grid, Link } from "@mui/material";
import styled from "@emotion/styled";
import { FlexDiv, Image } from "../../../Styles/style";
import { PageWrapper } from "../../../Styles/style";
import heroImage from "../../../../assets/heroImage.png";
import x from "../../../../assets/x.png";
import tg from "../../../../assets/tg.png";
import audio from "../../../../assets/audio.mp3";
import SectionsHeading from "../../SectionsHeading";
const ContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  padding: 0 !important;
`;

const GridWraper = styled(Grid)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  @media (max-width: 599px) {
    /* align-items: center; */
    padding-bottom: 50px;
  }
`;

const HeroSection = () => {
  return (
    <PageWrapper id="home">
      <ContinerWraper maxWidth="xxl">
        <Container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: { xs: "20px", lg: "20px 0" },
          }}
        >
          <Link
            href="/"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "10px",
              textDecoration: "none",
            }}
          >
            <SectionsHeading sx={{ fontWeight: "bolder !important" }}>
              ADHD!
            </SectionsHeading>
          </Link>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "10px",
            }}
          >
            <Link
              href="https://twitter.com/Adhd_Token"
              target="blank"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                columnGap: "10px",
                textDecoration: "none",
              }}
            >
              <Image src={x} alt="" />
              <SectionsHeading
                fs599="0px"
                sx={{ fontWeight: "bolder !important" }}
              >
                Twitter
              </SectionsHeading>
            </Link>
            <Link
              href="https://t.me/Adhd_Token"
              target="blank"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                columnGap: "10px",
                textDecoration: "none",
              }}
            >
              <Image src={tg} alt="" />
              <SectionsHeading
                fs599="0px"
                sx={{ fontWeight: "bolder !important" }}
              >
                Telegram
              </SectionsHeading>
            </Link>
          </div>
        </Container>
        <audio style={{position:"absolute" , top:"15%",left:"0%"}} src={audio} controls></audio>
        <Container
          sx={{
            width: "100%",
            minHeight: "calc(100vh - 100px)",
          }}
        >
          <GridWraper container>
            <Grid
              item
              xs={12}
              sx={{
                display: "grid",
                placeItems: "center",
                padding: "0px 0 0",
              }}
            >
              <Image data-aos="zoom-in" mw="30%" mw599="50%" src={heroImage} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={10}
              sx={{
                padding: "20px 0 30px",
              }}
            >
              <SectionsHeading
                data-aos="fade-up"
                color="red"
                fs="100px"
                fs899="50px"
                fs599="46px"
              >
                HI! I AMmmm.....?
              </SectionsHeading>
              <FlexDiv data-aos="fade-up">
                <SectionsHeading fs599="26px" fm="LDFComicSans" sx={{wordBreak:"break-all"}}>
                  Address: 0x812659e3B159F860508D9bc5cE97C0B7A99B10C6
                </SectionsHeading>
              </FlexDiv>
              <FlexDiv data-aos="fade-up">
                <SectionsHeading fs599="26px" fm="LDFComicSans" sx={{wordBreak:"break-all"}}>
                  Email: info@adhdtoken.org
                </SectionsHeading>
              </FlexDiv>
            </Grid>
          </GridWraper>
        </Container>
      </ContinerWraper>
    </PageWrapper>
  );
};

export default HeroSection;
